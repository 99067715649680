<template>
  <div class="row">
      <div class="col-md-12 mb-3" v-if="enableFilter">
        <div class="py-1">
          <div class="md-form mb-0">
            <v-select  :disabled="disabled" :placeholder="placeholder" :label="labelField" ref='filterField'  :searchable="searchable" :clearable="clearable" :options="options" @search="delayFetchOptions" @input="addSelectedOption" :filter-by="filter" >
              <template slot="no-options">
                {{ noOptions }}
              </template>
              <template slot="option" slot-scope="option">
                <slot name="option-content">
                  <div class="d-center" v-bind:class="{ 'option-disabled': isIncluded(option) }">
                    {{ getLabel(option) }}
                  </div>
                </slot>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  {{ getLabel(option) }}
                </div>
              </template>
            </v-select>
          </div>
        </div>
      </div>

    <div class="col-12 col-md-12">
      <table class="table table-hover">
        <thead>
          <tr>
            <th v-for="(column, index) in tableFields" :data-idx="index" :key="index" :width="column.width" >
              {{ column.title }}
            </th>
            <th v-if="enableStatus === null">
              <div v-if="enableFilter">
                {{ $t('email')}}
              </div>
              <div v-else>
                <label class="ui-switch no-margin small switch-solid switch-warning">
                  <input tabindex="-1" :value="'all'" type="checkbox" ref="status" :checked="allSelected" v-on:change="toggleSelectAll"><span></span></label>
              </div>
            </th>
            <th v-if="whatsappToggle">
              <div>
                {{ $t('whatsapp')}}
              </div>
            </th>
            <th>
            </th>
          </tr>
        </thead>
        <tbody class="content">
        <tr v-for="(item, index) in computedValue" :data-idx="index" :key="index" @click="onRowClicked(item, index, $event)">
          <td v-for="(column, index) in tableFields" :data-idx="index" :key="index">{{ item[column.name] }}</td>
          <td v-if="enableStatus === null">
            <label class="ui-switch no-margin small switch-solid switch-warning">
            <input tabindex="-1" :value="index" type="checkbox" ref="status" :checked="(item.status == 1)" v-on:change="toggleStatus"><span></span></label>
          </td>
          <div v-if="isPortalTester() && whatsappToggle">
            <td v-if="checkPhoneNumber(item).exists">
              <label class="ui-switch no-margin small switch-solid switch-warning">
                <input tabindex="-1" :value="index" type="checkbox" ref="status" :checked="(item.wpstatus == 1)" v-on:change="toggleWPStatus"><span></span>
              </label>
            </td>
            <td v-else>
              <label class="ui-switch no-margin small switch-solid switch-warning">
                {{ checkPhoneNumber(item).reason }}
              </label>
            </td>
          </div>
          <div v-else>
            <td >
              <label class="ui-switch no-margin small switch-solid switch-warning">
                Você não tem permissão
              </label>
            </td>
          </div>
          <td v-if="enableFilter">
            <div class="buttonClose d-inline-block" @click.stop="removeSelectedOption(item[idField])">
              <span ><i class="rc-Ativo-36 font-11"></i></span>
            </div>
          </td>
        </tr>
        <!-- If no have content to show -->
        <tr v-if="computedValue.length <= 0">
          <td colspan="6">{{ emptyMessage }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
// vue-select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'AdvancedMultipleSelect',
  props: {
    delay: {
      required: false,
      type: Number,
      default: 400
    },
    enableFilter: {
      type: Boolean,
      default: true
    },
    enableStatus: {
      type: Number,
      default: null
    },
    whatsappToggle: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: global.instanceApp.$i18n.t('filter.Filter_by')
    },
    apiUrl: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => {
        return []
      }
    },
    tableFields: {
      type: Array,
      default: () => {
        return []
      }
    },
    emptyMessage: {
      type: String,
      default: global.instanceApp.$i18n.t('noting selected')
    },
    noOptions: {
      type: String,
      default: 'Nenhum resultado encontrado'
    },
    fetchOptions: {
      type: Function,
      required: false,
      default: () => {
        return false
      }
    },
    searchField: {
      type: String,
      default: ''
    },
    labelField: {
      type: String,
      default: 'name'
    },
    idField: {
      type: String,
      default: 'id'
    },
    httpMethod: {
      type: String,
      default: 'get',
      validator: (value) => {
        return ['get', 'post'].indexOf(value) > -1
      }
    },
    value: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * Can the user clear the selected property.
     * @type {Boolean}
     */
    clearable: {
      type: Boolean,
      default: true
    },
    /**
     * Enable/disable filtering the options.
     * @type {Boolean}
     */
    searchable: {
      type: Boolean,
      default: true
    },
    customLabel: {
      type: Function,
      default: null
    }
  },
  components: {
    vSelect
  },
  data () {
    return {
      searchTerm: null,
      selectedValue: null,
      timer: 0
    }
  },
  methods: {
    checkPhoneNumber (item) {
      if (!item || !item.phone) {
        return {
          exists: false,
          reason: 'Contato sem número'
        }
      }

      let phone = item.phone.replace(/[+\-().:; ]/g, '')

      if (!/^\d+$/.test(phone)) {
        return {
          exists: false,
          reason: 'Número inválido'
        }
      }

      const length = phone.length

      if (length <= 9) {
        return {
          exists: false,
          reason: 'Número inválido'
        }
      }

      if (length === 12 && phone[0] !== '0') {
        return {
          exists: false,
          reason: 'Número inválido'
        }
      }

      if (length === 13 || (length === 12 && phone[0] === '0') || (length === 14 && phone[2] === '0') || length === 11 || length === 10) {
        return {
          exists: true,
          reason: 'Número válido'
        }
      }
    },
    onRowClicked (dataItem, dataIndex, event) {
      this.$emit('rowClicked', { data: dataItem, index: dataIndex, event: event })
      return true
    },
    isIncluded: function (val) {
      let _me = this
      let selected = this.value.find((x) => {
        return x[_me.idField] === val[_me.idField]
      })

      if (selected !== undefined) {
        return true
      }
      return false
    },
    isPortalTester () {
      let status = false
      let userInfo = this.$store.getters.userInfo || null
      if ((userInfo && userInfo.roles.indexOf('ROLE_PORTAL_TESTER') !== -1) || (userInfo && userInfo.roles.indexOf('ROLE_SUPER_ADMIN') !== -1)) {
        status = true
      }
      return status
    },
    toggleSelectAll () {
      let activeStatus = this.enableStatus
      if (activeStatus === null) {
        activeStatus = 1
      }

      if (this.allSelected) {
        for (let i in this.value) {
          let item = this.value[i]
          item.status = 2
          this.value.splice(i, 1, item)
        }
      } else {
        for (let i in this.value) {
          let item = this.value[i]
          item.status = activeStatus
          this.value.splice(i, 1, item)
        }
      }
      this.$emit('toggleAll', this.value)
    },
    toggleStatus (e) {
      let index = e.target.value

      let item = this.value[index]

      item.status = ((e.target.checked) ? 1 : 2)
      this.$set(this.value, index, item)
      this.$emit('toggleStatus', item)
    },
    toggleWPStatus (e) {
      let index = e.target.value

      let item = this.value[index]

      item.wpstatus = ((e.target.checked) ? 1 : 2)
      this.$set(this.value, index, item)
      this.$emit('toggleWPStatus', item)
    },
    addSelectedOption (val) {
      let defaultStatus = 1
      if (this.enableStatus !== null) {
        defaultStatus = this.enableStatus
      }

      if (val != null) {
        if (!this.isIncluded(val)) {
          val.status = defaultStatus
          this.value.push(val)
          this.$emit('onInput', { data: val })
        }
        this.$refs.filterField.clearSelection()
      }
    },
    removeSelectedOption (val) {
      if (val != null) {
        let _this = this
        // Find position of removed item
        let index = this.value.findIndex(function (element) {
          return element[_this.idField] === val
        })
        // remove element from selected options
        this.value.splice(index, 1)
        this.$emit('onRemoved', val)
      }
    },
    getLabel (option) {
      if (this.customLabel) {
        return this.customLabel(option)
      } else {
        return option[this.labelField]
      }
    },
    delayFetchOptions (search, loading) {
      if (search.length > 0) {
        let _this = this
        window.clearTimeout(this.timer)

        this.timer = window.setTimeout(function () {
          _this.fetchOptions(search, loading)
        }, this.delay)
      }
    },
    filter (option, label, search) {
      let temp = this.$util.slugify(search.toLowerCase().trim())

      return (
        this.$util.slugify(option[this.labelField].toLowerCase()).indexOf(temp) > -1 ||
        ((option.email) && this.$util.slugify(option.email.toLowerCase()).indexOf(temp) > -1)
      )
    }
  },
  computed: {
    allSelected () {
      let _this = this
      if (_this.value.length <= 0) {
        return false
      }

      let activeStatus = _this.enableStatus
      if (activeStatus === null) {
        activeStatus = 1
      }

      let activeValues = _this.value.filter(function (x) {
        return x.status === activeStatus
      })

      if (activeValues.length === _this.value.length) {
        return true
      }

      return false
    },
    computedValue: function () {
      if (this.enableStatus === null) {
        return this.value
      }

      let _self = this

      return this.value.filter(function (status) {
        return status.status === _self.enableStatus
      })
    }
  }
}
</script>

<style scoped>
  .buttonClose:not(:disabled):not(.disabled) {
    cursor: pointer;
    font-size: 12px;
  }

  .option-disabled {
    cursor: not-allowed;
    color: #83dadb;
  }
</style>
