<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="row">
      <div class="col-md-12">
        <div class="close-button mb-0 text-right">
          <div class="btn btn-link buttonClose d-inline-block" @click="onClose()">
            <span><i class="text-primary rc-Ativo-36"> </i></span>
          </div>
        </div>
      </div>

      <!-- Template -->
      <div class="col-md-12 col-sm-4 form-group mb-4 mt-2">
        <div class="col-md-11">
        <label class="mb-0">Template</label>
        <v-select name="task.idTemplate" label="idTemplate.name"  :clearable="false" v-model="selectedTemplate"  :options="templates" @input="onSelectTemplate" >
          <template slot="option" slot-scope="option">
            <div class="d-center">
              {{ option.idTemplate.name }}
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            <div class="selected d-center">
              {{ option.idTemplate.name }}
            </div>
          </template>
        </v-select>
        <transition name="slide" mode="">
          <small v-if="errors.first('task.idTemplate')" class="royalc-error-field">{{ errors.first('task.idTemplate') }}</small>
        </transition>
          </div>
      </div>

      <div v-if="subject" class="col-md-12 col-sm-4 form-group mb-4 mt-2" style="margin: 0 auto;">
        <div class="col-md-12">
          <label class="mb-0">{{$t('mails.template.subject')}}</label>
          <div v-html="subject"></div>
        </div>

        <div v-if="content" class="col-md-12">
          <label class="mt-3 mb-0">{{$t('mails.template.content_html')}}</label>
        </div>
        <div class="col-md-12"  v-html="content"></div>

        <div v-if="files.length > 0" class="col-md-12">
          <label class="mt-3 mb-0">{{$t('mails.template.files')}}</label>
          <ul class="list-unstyled">
            <li v-for="(file, index) in files" v-bind:key="index">
              <a  target="_blank" :href="file.externalLink">{{ file.fileName }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Services
import MailService from '@/services/MailService'

// Components
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'MessagePreviewModal',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('schedules.title') + ' ' + this.$i18n.t('sidebar.tariffs') + ' - %s'
    }
  },
  data () {
    return {
      isLoading: false,
      fullPage: true,
      content: null,
      subject: null,
      sequenciaEnvio: 0,
      selectedTemplate: null,
      files: []
    }
  },
  components: {
    Loading,
    vSelect
  },
  created () {
    let _this = this
    _this.isLoading = true

    _this.selectedTemplate = this.templates[this.sequenciaEnvio]

    MailService.previewTemplate(this.templates[this.sequenciaEnvio].idTemplate.id_template, this.contact).then((res) => {
      _this.content = res.data.content
      _this.subject = res.data.subject
      _this.files = res.data.files
    }).catch(() => {
      _this.$emit('close')
    }).finally(() => {
      _this.isLoading = false
    })
  },
  methods: {
    onSelectTemplate (val) {
      let _this = this
      _this.sequenciaEnvio = this.templates.findIndex((template) => {
        return val.idTemplate.id_template === template.idTemplate.id_template
      })

      _this.isLoading = true

      MailService.previewTemplate(this.templates[this.sequenciaEnvio].idTemplate.id_template, this.contact).then((res) => {
        _this.content = res.data.content
        _this.subject = res.data.subject
        _this.files = res.data.files
      }).catch(() => {
        _this.$emit('close')
      }).finally(() => {
        _this.isLoading = false
      })
    },
    applyTranslate (val) {
      // Se não informado retorna vazio
      if (!val) {
        return ''
      }

      let translated = this.$i18n.t('taskCampaign.fields.' + val)

      if (translated.includes('taskCampaign.fields.')) {
        return val
      }

      return translated
    },
    onClose () {
      // this.$emit('before-close')
      this.$emit('close')
    }
  },
  props: {
    templates: {
      type: Array,
      description: 'Template list Info',
      default: null
    },
    contact: {
      type: Object,
      description: 'Contact selected option',
      default: null
    }
  }
}
</script>

<style scoped>
  .close-button {
    position: absolute;
    right: 15px;
    z-index: 99;
  }

  label {
    font-weight: bold;
  }

  .swal2-popup {
    width: inherit !important;
  }

  .card-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md-form-control {
    height: 30px;
  }

</style>
