import Http from '@/services/Http'
import BaseService from '@/services/BaseService'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import httpBuildQuery from 'http-build-query'
// import httpBuildQuery from 'http-build-query'

dayjs.extend(customParseFormat)
const urlAPI = process.env.VUE_APP_APIENDPOINT

export const MailService = {
  /**
   * http://localhost/api/V1/customer
   *
   * @param filter
   * @returns {Promise<any>}
   */
  getMailTypes () {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/mail/types`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  /**
   * http://localhost/api/V1/customer
   *
   * @param filter
   * @returns {Promise<any>}
   */
  getContacts (filter) {
    let queryString = httpBuildQuery(filter)

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/mail/contacts?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getRecipients (filter) {
    let queryString = httpBuildQuery(filter)

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/mail/recipients?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getVariables (filter) {
    let queryString = httpBuildQuery(filter)

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/mail/variables?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getVariableSearch (filter) {
    let queryString = httpBuildQuery(filter)

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/mail/variables/search?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  /**
   * http://localhost/api/V1/customer
   *
   * @param filter
   * @returns {Promise<any>}
   */
  getMailInfo (id) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/mail/${id}/info`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getTemplates (filter) {
    let queryString = httpBuildQuery(filter)
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/mailtemplate?${queryString}`).then(function (res) {
          resolve({ data: res.data })
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getOneTemplate (id) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/mailtemplate/${id}`).then(function (res) {
          resolve({ data: res.data })
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  newTemplate (content) {
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/mailtemplate`, content).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(new Error('formulário invalido'))
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  editTemplate (id, content) {
    // Todo validate form data
    try {
      return new Promise(function (resolve, reject) {
        Http.put(`${urlAPI}v2/mailtemplate/${id}`, content).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(new Error('formulário invalido'))
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  deleteTemplate (id) {
    try {
      return new Promise(function (resolve, reject) {
        Http.delete(`${urlAPI}v2/mailtemplate/${id}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getAutomaticEmail (id) {
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/mail/${id}/automatic`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  newAutomaticEmail (content) {
    // Todo validate form data
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/mail/automatic`, content).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(new Error('formulário invalido'))
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  editAutomaticEmail (id, content) {
    // Todo validate form data
    try {
      return new Promise(function (resolve, reject) {
        Http.put(`${urlAPI}v2/mail/${id}/automatic`, content).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(new Error('formulário invalido'))
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  previewTemplate (id, content) {
    // Todo validate form data
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/mailtemplate/${id}/preview`, { contact: JSON.stringify(content) }).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(new Error('formulário invalido'))
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getEventHistory (id, filter) {
    let queryString = httpBuildQuery(filter)
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v3/mail/${id}?${queryString}`).then(function (res) {
          resolve({ data: res.data })
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  }
}

export default MailService
