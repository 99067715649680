<template>
  <div>
    <div v-for="(item, index) in options" :data-idx="index" :key="index"
         class="btn btn-link badge-circle btn-outline-primary mr-2 "
         v-bind:class="{ 'active': item.active }"
         :title="item.title"
         v-on:click="toggleValue"
    >
      {{ item.name }}
    </div>
  </div>
</template>

<script>

export default {
  name: 'MultipleToggle',
  props: {
    options: {
      type: Array,
      default: () => {
        return []
      }
    },
    /**
     * Can the user clear the selected property.
     * @type {Boolean}
     */
    clearable: {
      type: Boolean,
      default: true
    }
  },
  components: {
  },
  data () {
    return {
      selectedObjects: null
    }
  },
  methods: {
    toggleValue (e) {
      let index = e.target.getAttribute('data-idx')
      let item = this.options[index]
      item.active = !item.active
      this.$set(this.options, index, item)
    }
  }
}
</script>
