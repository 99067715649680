<template>
  <div class="row">
    <div class="col-md-4 form-group mb-6 pr-0 pl-1">
      <v-select label="nome" :clearable="false" :searchable="true" v-model="value.fieldA" @input="onSelectFieldA" :options="fieldsList"
                >
        <template slot="option" slot-scope="option">
          <div class="d-center">
            {{ (option.nome) ? $t('taskCampaign.fields.' + option.nome) : ''  }}
          </div>
        </template>
        <template slot="selected-option" slot-scope="option">
          <div class="selected d-center">
            {{ (option.nome) ? $t('taskCampaign.fields.' + option.nome) : ''  }}
          </div>
        </template>
      </v-select>
    </div>
    <div class="col-md-3 form-group mb-6 pr-0 pl-1">
      <v-select label="nome" :value="value.condition" :clearable="false" :searchable="true" @input="onSelectCondition" :options="activeConditions">
        <template slot="option" slot-scope="option">
          <div class="d-center">
            {{ option.nome }}
          </div>
        </template>
        <template slot="selected-option" slot-scope="option">
          <div class="selected d-center">
            {{ option.nome }}
          </div>
        </template>
      </v-select>
    </div>
    <div class="col-md-4 form-group mb-6 pr-0 pl-1" v-if="value.fieldA.tipo !== 7" >
      <v-select label="nome"  :value="(value.value)? {nome: value.value} : value.fieldB" :clearable="false" :searchable="true" :pushTags="true" :taggable="true" @input="onSelectFieldB" :options="activeFields">
        <template slot="option" slot-scope="option">
          <div class="d-center">
            {{ applyTranslate(option.nome)  }}
          </div>
        </template>
        <template slot="selected-option" slot-scope="option">
          <div class="selected d-center">
            {{ applyTranslate(option.nome)  }}
          </div>
        </template>
      </v-select>
      <transition name="slide" mode="out-in">
        <small v-if="value.error && value.error.length > 0" class="royalc-error-field">{{ value.error[0] }}</small>
      </transition>
    </div>
    <div  v-else class="col-md-4 form-group mb-6 pr-0 pl-1"  >
      <v-select label="nome"  :value="value.value" :clearable="false" :searchable="true" :pushTags="true" :taggable="false" @input="onSelectValue" :options="valueFields">
        <template slot="option" slot-scope="option">
          <div class="d-center">
            {{ applyTranslate(option.nome)  }}
          </div>
        </template>
        <template slot="selected-option" slot-scope="option">
          <div class="selected d-center">
            {{ applyTranslate(option.nome)  }}
          </div>
        </template>
      </v-select>
      <transition name="slide" mode="out-in">
        <small v-if="value.error && value.error.length > 0" class="royalc-error-field">{{ value.error[0] }}</small>
      </transition>
    </div>
    <div class="col-md-1 form-group mb-6 pr-0 pl-1">
      <v-select label="nome"  :value="value.operator"  :clearable="false" :searchable="true" @input="onSelectOperator" :options="operatorList">
        <template slot="option" slot-scope="option">
          <div class="d-center">
            {{ option.nome }}
          </div>
        </template>
        <template slot="selected-option" slot-scope="option">
          <div class="selected d-center">
            {{ option.nome }}
          </div>
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
// Form
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'FieldFilter',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('schedules.title') + ' ' + this.$i18n.t('sidebar.tariffs') + ' - %s',
      breadcrumbs: [
        // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },

      ]
    }
  },
  data () {
    return {
      operatorList: [
        {
          id: 1,
          nome: 'E'
        },
        {
          id: 2,
          nome: 'OU'
        }
      ]

    }
  },
  components: {
    vSelect
  },
  computed: {
    activeConditions () {
      let _this = this

      // Tipos: 1: Texto | 2: Numerico | 3: Data | 4: Opções
      let conditionList = [
        {
          id: 1,
          nome: 'Igual à'
        },
        {
          id: 2,
          nome: 'Diferente de'
        },
        {
          id: 3,
          nome: 'Maior ou igual à',
          tipos: [2, 3]
        },
        {
          id: 4,
          nome: 'Menor ou igual à',
          tipos: [2, 3]
        },
        {
          id: 5,
          nome: 'Contém',
          tipos: [1]
        }
      ]

      return conditionList.filter(function (item) {
        // Se não tiver o atributo é válido para todos
        if (!item.hasOwnProperty('tipos')) {
          return true
        }

        if (_this.value) {
          return item.tipos.includes(_this.value.fieldA.tipo)
        }
      })
    },
    valueFields () {
      let _this = this

      let options = []

      if (_this.value.fieldA && _this.value.fieldA.tipo === 7) {
        options.push({ nome: 'Sim' })
        options.push({ nome: 'Não' })
      }

      return options
    },
    activeFields () {
      let _this = this

      let optionsFields = _this.fields.filter(function (item) {
        // Se não selecionado o campo A não dá opções
        if (!_this.value.fieldA || !_this.value.fieldA.id) {
          return false
        }

        // Se campos forem do mesmo tipo pode selecionar
        if (item.tipo === _this.value.fieldA.tipo) {
          if (item.codigo === 'dia_mes') {
            if (_this.value.condition && (_this.value.condition.id === 1 || _this.value.condition.id === 2)) {
              return true
            } else {
              return false
            }
          } else if (item.codigo === 'nulo') {
            if (_this.value.condition && (_this.value.condition.id === 1 || _this.value.condition.id === 2)) {
              return true
            } else {
              return false
            }
          } else {
            return true
          }
        }
      })

      return optionsFields
    },
    fieldsList () {
      let _this = this

      let optionsFields = _this.fields.filter(function (item) {
        // Se não selecionado o campo A não dá opções
        if (item.codigo === 'nulo' || item.codigo === 'hoje' || item.codigo === 'dia_mes') {
          return false
        } else {
          return true
        }
      })

      return optionsFields
    }
  },
  props: {
    fields: {
      type: [Array],
      description: 'Field available to select',
      default: (val) => {
        val = []
        return val
      }
    },
    value: {
      type: [Object],
      description: 'Input value'
    }
  },
  methods: {
    applyTranslate (val) {
      // Se não informado retorna vazio
      if (!val) {
        return ''
      }

      let translated = this.$i18n.t('taskCampaign.fields.' + val)

      if (translated.includes('taskCampaign.fields.')) {
        return val
      }

      return translated
    },
    update (key, value) {
      this.$emit('input', { ...this.value, [key]: value })
    },
    onSelectFieldA (val) {
      this.value.value = null
      this.value.fieldB = null
      this.value.condition = null
      this.$emit('input', { ...this.value })
    },
    onSelectOperator (val) {
      this.value.operator = val
      this.$emit('input', { ...this.value })
    },
    onSelectCondition (val) {
      this.value.condition = val

      if (this.value.condition.id === 5) {
        this.value.fieldB = null
        this.value.value = null
      }
      this.$emit('input', { ...this.value })
    },
    onSelectValue (val) {
      delete this.value.error
      this.value.fieldB = null
      this.value.value = val.nome

      this.$emit('input', { ...this.value })
    },
    onSelectFieldB (val) {
      delete this.value.error
      if (val.hasOwnProperty('id')) {
        this.value.fieldB = val
        this.value.value = null
      } else {
        if (this.value.fieldA && this.value.fieldA.tipo === 3) {
          this.$validator.verify(val.nome, 'date_format:dd/MM/yyyy').then(res => {
            if (!res.valid) {
              this.value.error = res.errors
              this.$emit('input', { ...this.value })
            }
          })
        }
        this.value.fieldB = null
        this.value.value = val.nome
      }

      this.$emit('input', { ...this.value })
    }
  },
  created () {
    let _this = this
    if (_this.value.operator && !_this.value.operator.hasOwnProperty('id')) {
      _this.value.operator = _this.operatorList.find((x) => {
        return x.id === _this.value.operator
      })
    } else if (!_this.value.operator) {
      _this.value.operator = _this.operatorList[0]
    }

    if (_this.value.condition && !_this.value.condition.hasOwnProperty('id')) {
      _this.value.condition = _this.activeConditions.find((x) => {
        return x.id === _this.value.condition
      })
    }
  }
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .card-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md-form-control {
    height: 30px;
  }
</style>
